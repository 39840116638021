.report-section-notification {
  height: 18px;
}

.report-row-notification {
  height: 18px;
}

.report-section-report-text {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
