.dashboard-card {
  width: 260px;
  height: 140px !important;
  cursor: pointer;
  border-radius: 10px;
}

.dashboard-card-title {
  font-weight: bold;
}

